import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_CARTS,
  GET_USER_CARTS_SUCCESS,
  GET_USER_CARTS_FAIL,
  GET_USER_CART_DETAILS,
  GET_USER_CART_DETAILS_SUCCESS,
  GET_USER_CART_DETAILS_FAIL,
  DELETE_USER_CART,
  DELETE_USER_CART_SUCCESS,
  DELETE_USER_CART_FAIL,
  GET_USER_FAVOURITE_DETAILS,
  GET_USER_FAVOURITE_DETAILS_SUCCESS,
  GET_USER_FAVOURITE_DETAILS_FAIL,
  DELETE_USER_FAVOURITE,
  DELETE_USER_FAVOURITE_SUCCESS,
  DELETE_USER_FAVOURITE_FAIL,
} from "./actionTypes"

//all users
export const getUsers = (page, sort, searchText) => ({
  type: GET_USERS,
  payload: { page, sort, searchText },
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

//user details
export const getUserDetails = userId => ({
  type: GET_USER_DETAILS,
  payload: userId,
})

export const getUserDetailsSuccess = userDetails => ({
  type: GET_USER_DETAILS_SUCCESS,
  payload: userDetails,
})

export const getUserDetailsFail = error => ({
  type: GET_USER_DETAILS_FAIL,
  payload: error,
})

//update user
export const updateUser = (user, userId, history) => ({
  type: UPDATE_USER,
  payload: { user, userId, history },
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

//delete user
export const deleteUser = (userId, history) => ({
  type: DELETE_USER,
  payload: { userId, history },
})

export const deleteUserSuccess = (user, id) => ({
  type: DELETE_USER_SUCCESS,
  payload: { user, id },
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})

//all carts
export const getUserCarts = () => ({
  type: GET_USER_CARTS,
})

export const getUserCartsSuccess = userCarts => ({
  type: GET_USER_CARTS_SUCCESS,
  payload: userCarts,
})

export const getUserCartsFail = error => ({
  type: GET_USER_CARTS_FAIL,
  payload: error,
})

//user details
export const getUserCartDetails = userId => ({
  type: GET_USER_CART_DETAILS,
  payload: userId,
})

export const getUserCartDetailsSuccess = userCartDetails => ({
  type: GET_USER_CART_DETAILS_SUCCESS,
  payload: userCartDetails,
})

export const getUserCartDetailsFail = error => ({
  type: GET_USER_CART_DETAILS_FAIL,
  payload: error,
})

//delete cart
export const deleteUserCart = (userCartId, history) => ({
  type: DELETE_USER_CART,
  userCartId,
  history,
})

export const deleteUserCartSuccess = userCart => ({
  type: DELETE_USER_CART_SUCCESS,
  payload: userCart,
})

export const deleteUserCartFail = error => ({
  type: DELETE_USER_CART_FAIL,
  payload: error,
})

//user favourite details
export const getUserFavouriteDetails = userId => ({
  type: GET_USER_FAVOURITE_DETAILS,
  payload: userId,
})

export const getUserFavouriteDetailsSuccess = userFavouriteDetails => ({
  type: GET_USER_FAVOURITE_DETAILS_SUCCESS,
  payload: userFavouriteDetails,
})

export const getUserFavouriteDetailsFail = error => ({
  type: GET_USER_FAVOURITE_DETAILS_FAIL,
  payload: error,
})

//delete user favourite
export const deleteUserFavourite = (userFavouriteId, history) => ({
  type: DELETE_USER_Favourite,
  userFavouriteId,
  history,
})

export const deleteUserFavouriteSuccess = userFavourite => ({
  type: DELETE_USER_FAVOURITE_SUCCESS,
  payload: userFavourite,
})

export const deleteUserFavouriteFail = error => ({
  type: DELETE_USER_FAVOURITE_FAIL,
  payload: error,
})
