/* USERS */
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

/* USER_DETAILS */
export const GET_USER_DETAILS = "GET_USER_DETAILS"
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS"
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL"


/* Edit USER*/
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"


/* Delete USER*/
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"


// Cart Items

/* CARTS */
export const GET_USER_CARTS = "GET_USER_CARTS"
export const GET_USER_CARTS_SUCCESS = "GET_USER_CARTS_SUCCESS"
export const GET_USER_CARTS_FAIL = "GET_USER_CARTS_FAIL"

/* CART_DETAILS */
export const GET_USER_CART_DETAILS = "GET_USER_CART_DETAILS"
export const GET_USER_CART_DETAILS_SUCCESS = "GET_USER_CART_DETAILS_SUCCESS"
export const GET_USER_CART_DETAILS_FAIL = "GET_USER_CART_DETAILS_FAIL"


/* Delete CART*/
export const DELETE_USER_CART = "DELETE_USER_CART"
export const DELETE_USER_CART_SUCCESS = "DELETE_USER_CART_SUCCESS"
export const DELETE_USER_CART_FAIL = "DELETE_USER_CART_FAIL"


// Favourite Items

/* FAVOURITE_DETAILS */
export const GET_USER_FAVOURITE_DETAILS = "GET_USER_FAVOURITE_DETAILS"
export const GET_USER_FAVOURITE_DETAILS_SUCCESS = "GET_USER_FAVOURITE_DETAILS_SUCCESS"
export const GET_USER_FAVOURITE_DETAILS_FAIL = "GET_USER_FAVOURITE_DETAILS_FAIL"


/* Delete FAVOURITE*/
export const DELETE_USER_FAVOURITE = "DELETE_USER_FAVOURITE"
export const DELETE_USER_FAVOURITE_SUCCESS = "DELETE_USER_FAVOURITE_SUCCESS"
export const DELETE_USER_FAVOURITE_FAIL = "DELETE_USER_FAVOURITE_FAIL"
